/* eslint-disable no-unused-vars */
import { useCallback, useEffect, useLayoutEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { API_ENDPOINTS } from "../../api/endpoints/ApiEndpoints";
import axios from "axios";
import { toast } from "react-toastify";
import LoadingSpinner from "../LoadingSpinner";
import { setFacilityUsers } from "../../redux/reducers/facilitySlice";
import Button from "../Button";
import Pagination from "../pagination/Pagination";
import ProfileSettings from "../ProfileSettings";
import Modal from "../Modal";

import AddFacilityUser from "../forms/AddFacilityUser";

const FacilityUsers = () => {
  const facilityId = useSelector((state) => state.facility.facilityId);

  const facilityUsers = useSelector((state) => state.facility.facilityUsers);
  const token = localStorage.getItem("token");
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(true);
  const [searchQuery, setSearchQuery] = useState("");

  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;

  const [isDataLoaded, setIsDataLoaded] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

 const [isDeleting, setIsDeleting] = useState(false);

const fetchFacilityUsersData = useCallback(async () => {
  try {
    setIsLoading(true);
    const response = await axios.get(
      `${API_ENDPOINTS.GET_FACILITY_USERS}/${facilityId}`,
      {
        headers: {
          Authorization: token,
        },
        params: {
          page: currentPage,
          limit: itemsPerPage,
        },
      }
    );

    dispatch(setFacilityUsers(response.data.data));
    setIsLoading(false);
    setIsDataLoaded(true);
  } catch (error) {
    toast.error("Error fetching facility users");
    setIsLoading(false);
  }
}, [facilityId, token, dispatch, currentPage, itemsPerPage]);
    const deleteUser = async (userId) => {
      try {
        setIsDeleting(true); 
        const token = localStorage.getItem("token");
        const response = await axios.delete(
          "https://ktsdmsa.lifebanktest.com/api/admin/user",
          {
            headers: {
              Authorization: token,
            },
            data: {
              user_id: userId,
            },
          }
        );
        toast.success("User deleted successfully");


        fetchFacilityUsersData(); 
      } catch (error) {
        toast.error("Failed to delete user");
      } finally {
        setIsDeleting(false); 
      }
    };

  useEffect(() => {
    if (facilityId && !isDataLoaded) {
      fetchFacilityUsersData();
    }
  }, [fetchFacilityUsersData, facilityId, isDataLoaded]);

  const handlePageChange = (selectedPage) => {
    setCurrentPage(selectedPage.selected + 1);
  };

  const filteredFacilityData = facilityUsers.filter((item) =>
    item.username.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const totalPages = Math.ceil(filteredFacilityData.length / itemsPerPage);

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const paginatedFacilityData = filteredFacilityData.slice(
    startIndex,
    endIndex
  );

  useEffect(() => {
    setCurrentPage(1);
  }, [searchQuery]);

  const handleClick = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  useLayoutEffect(() => {
    if (!isModalOpen) {
      // Fetch data when the Modal is closed
      fetchFacilityUsersData();
    }
  }, [isModalOpen, fetchFacilityUsersData]);

  if (isLoading || !isDataLoaded) {
    return <LoadingSpinner />;
  }

  return (
    <>
      <div className="overflow-x-auto p-6">
        <div className="flex justify-between items-center mb-6">
          <h1 className="text-2xl font-semibold text-gray-900">
            Facility Users
          </h1>
          <ProfileSettings />
        </div>
        <div className="flex justify-end items-center gap-3 mb-3">
          <Button color="primary" onClick={handleClick}>
            Add Facility User
          </Button>
          <input
            type="text"
            placeholder="Search..."
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            className="bg-white border border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500 rounded-md py-2 px-4 block w-72 appearance-none leading-normal"
          />
        </div>
        <table className="w-full divide-y divide-gray-200">
          <thead className="bg-[#06652e] h-16 text-center">
            <tr className="text-[#ffff] text-base">
              <th className="px-6 py-3  text-xs font-medium uppercase tracking-wider">
                ID
              </th>
              <th className="px-6 py-3  text-xs font-medium uppercase tracking-wider">
                Username
              </th>
              <th className="px-6 py-3 text-xs font-medium  uppercase tracking-wider">
                Email
              </th>
              <th className="px-6 py-3 text-xs font-medium  uppercase tracking-wider">
                Phone
              </th>

              {/* <th className="px-6 py-3  text-xs font-medium text-gray-500 uppercase tracking-wider">
                Role
              </th> */}
              <th className="px-6 py-3  text-xs font-medium  uppercase tracking-wider">
                Action
              </th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {paginatedFacilityData.map((user) => (
              <tr key={user.id} className="text-center">
                <td className="px-6 py-4 whitespace-nowrap">{user.id}</td>
                <td className="px-6 py-4 whitespace-nowrap">{user.username}</td>
                <td className="px-6 py-4 whitespace-nowrap">{user.email}</td>
                <td className="px-6 py-4 whitespace-nowrap">{user.phone}</td>

                {/* <td className="px-6 py-4 whitespace-nowrap">{user.role}</td> */}
                <td
                  className="px-6 py-4 whitespace-nowrap  text-sm font-medium flex justify-center items-center gap-3  text-blue-600 hover:text-red-900 text-center cursor-pointer"
                  onClick={() => deleteUser(user.id)}
                >
                  Delete user
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <div className="flex justify-end items-center mt-3">
          <Pagination
            pageCount={totalPages}
            onPageChange={handlePageChange}
            forcePage={currentPage - 1}
          />
        </div>
      </div>
      {isModalOpen && (
        <Modal isOpen={isModalOpen} onClose={closeModal}>
          {/* <h1>Add User</h1>
           */}
          <AddFacilityUser facility_id={facilityId} closeModal={closeModal} />
        </Modal>
      )}
    </>
  );
};

export default FacilityUsers;
