import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const fetchProcessedOrders = createAsyncThunk(
  "processedOrders/fetchProcessedOrders",
  async (_, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");

      const response = await axios.get(
        "https://ktsdmsa.lifebanktest.com/api/admin/processed/orders",
        {
          headers: {
            Authorization: token,
          },
        }
      );

      return response.data.data; 
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const viewOrders = createAsyncThunk(
  "processedOrders/viewOrders",
  async (itemId, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");

      const response = await axios.get(
        `https://ktsdmsa.lifebanktest.com/api/admin/process/${itemId}`,
        {
          headers: {
            Authorization: token,
          },
        }
      );
      const responseData = await response.json(); 
      console.log(responseData);
      return responseData.data; 
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const processedOrdersSlice = createSlice({
  name: "processedOrders",
  initialState: {
    data: [],
    orders: [],
    isLoading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchProcessedOrders.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchProcessedOrders.fulfilled, (state, action) => {
        state.data = action.payload;
        state.isLoading = false;
        state.error = null;
      })
      .addCase(fetchProcessedOrders.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })
   
  },
});

export default processedOrdersSlice.reducer;
