/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { setRequestData } from "../../redux/reducers/requestDataSlice";
import axios from "axios";

import { Navigate, useNavigate } from "react-router-dom";
import LoadingSpinner from "../LoadingSpinner";
import { toast } from "react-toastify";
import useSortableTable from "../../hooks/useSortableTable";

const ScheduledReq = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const data = useSelector((state) => state.requestData);

  const [selectedItemId, setSelectedItemId] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 6;
  const [isLoading, setIsLoading] = useState(true);

  const handleNavigate = (e) => {
    navigate("/dashboard");
  };

  useEffect(() => {
    const fetchRequestData = async () => {
      try {
        const token = localStorage.getItem("token");
        if (!token) {
          return;
        }

        setIsLoading(true);
        const response = await axios.get(
          "https://ktsdmsa.lifebanktest.com/api/admin/dashboard",
          {
            headers: {
              Authorization: token,
            },
          }
        );
        const responseData = response.data;

        if (responseData.data && responseData.data.request) {
          const scheduledRequests = responseData.data.request.filter(
            (item) => item.type === "Schedule"
          );
          dispatch(setRequestData(scheduledRequests));
        }

        setIsLoading(false);
      } catch (error) {
        toast.error("Error fetching data");
        setIsLoading(false);
      }
    };

    fetchRequestData();
  }, [dispatch]);

  const handleViewItems = (itemId, type) => {
    setSelectedItemId(itemId);
    navigate(`/dashboard/${type.toLowerCase()}/${itemId}`);
  };

  const handleSeeMore = (e) => {
    e.preventDefault();

    const totalItems = data.length;
    const totalPages = Math.ceil(totalItems / itemsPerPage);

    setCurrentPage((prevPage) => {
      if (prevPage < totalPages) {
        return prevPage + 1;
      } else {
        return 1;
      }
    });
  };


  const handleBack = () => {
    navigate("/dashboard");
  };

     const { sortedData, sortBy, sortDirection, handleSort } =
       useSortableTable(data);
  
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    const paginatedData = sortedData.slice(startIndex, endIndex);

     const tableHeaders = [
       { label: "RQ ID", columnName: "id" },
       { label: "Facility", columnName: "facility_name" },
       { label: "Order Type", columnName: "type" },
       { label: "Due Date", columnName: "created" },
       { label: "No. of Products", columnName: "product" },
       { label: "Action", columnName: "action" },
     ];

  if (isLoading) {
    return <LoadingSpinner />;
  }

  if (data.length === 0) {
    return (
      <div className="container">
        <div className="flex justify-center items-center h-screen flex-col">
          <p className="text-4xl font-bold text-center mb-4">
            No Scheduled Requests
          </p>

          <button
            className="bg-[#06652e] hover:bg-[#06652e] text-white font-bold py-4 px-4 rounded"
            onClick={handleNavigate}
          >
            Back to dashboard
          </button>
        </div>
      </div>
    );
  }

  return (
    <>
      <div className="flex justify-between items-center p-6">
        <h2 className="font-semibold text-[20px]" onClick={handleNavigate}>
          Scheduled Requests
        </h2>
        <p
          className="text-[#06652e] hover:underline cursor-pointer text-2xl"
          onClick={handleSeeMore}
        >
          See more - {currentPage} of {Math.ceil(data.length / itemsPerPage)}
        </p>
      </div>

      <div className="flex justify-end items-center p-6">
        <button
          className="bg-[#06652e] hover:bg-[#06652e] text-[#F8FBFF] font-normal py-2 px-4 rounded text-sm cursor-pointer"
          onClick={handleBack}
        >
          Back to Dashboard
        </button>
      </div>

      <div className="max-w-full overflow-x-auto p-6">
        <table className="w-full text-center shadow-lg">
          <thead className="bg-[#06652e]">
            <tr className="text-[#ffff] text-base">
              {tableHeaders.map((header) => (
                <th
                  key={header.columnName}
                  className="py-4 px-2 sm:px-4 md:px-6 lg:px-8 cursor-pointer"
                  onClick={
                    header.columnName !== "action"
                      ? () => handleSort(header.columnName)
                      : undefined
                  }
                >
                  {header.label}{" "}
                  {sortBy === header.columnName && (
                    <span>{sortDirection === "asc" ? "▲" : "▼"}</span>
                  )}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {paginatedData.length === 0 ? (
              <tr>
                <td
                  colSpan={6}
                  className="text-4xl font-bold text-center mb-2 mt-2 h-28"
                >
                  No Pending Requests
                </td>
              </tr>
            ) : (
              paginatedData.map((item) => (
                <tr
                  key={item.id}
                  className="border-b border-gray-400 border-opacity-20 bg-[#F8FBFF]"
                >
                  <td className="py-4 px-2 sm:px-4 md:px-6 lg:px-8">
                    {item.id}
                  </td>
                  <td className="py-4 px-2 sm:px-4 md:px-6 lg:px-8">
                    {item.facility_name}
                  </td>
                  <td
                    className="px-4 py-2"
                    style={{
                      color: item.type === "Schedule" ? "#0080FF" : "",
                    }}
                  >
                    {item.type}
                  </td>
                  <td className="py-4 px-2 sm:px-4 md:px-6 lg:px-8">
                    {item.created}
                  </td>
                  <td className="py-4 px-2 sm:px-4 md:px-6 lg:px-8">
                    {item.product}
                  </td>
                  {/* "Action" column cells */}
                  <td className="py-4 px-2 sm:px-4 md:px-6 lg:px-8">
                    {(item.type === "Emergency" ||
                      item.type === "Schedule" ||
                      item.type === "Routine") && (
                      <button
                        className="bg-[#06652e] hover:bg-[#06652e] text-[#F8FBFF] font-normal py-2 px-4 rounded text-sm"
                        onClick={() => handleViewItems(item.id, item.type)}
                      >
                        View Order
                      </button>
                    )}
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </table>
      </div>

      {selectedItemId && (
        <>
          {data.find((item) => item.id === selectedItemId)?.type ===
            "Schedule" && (
            <Navigate to={`/dashboard/schedule/${selectedItemId}`} />
          )}
        </>
      )}
    </>
  );
};


export default ScheduledReq;
